<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="proveForm"
               label-width="140px"
               :rules="rules" ref="proveForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="proveForm.xingming" disabled placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="实习岗位" prop="gangweimingcheng">
          <el-input v-model="proveForm.gangweimingcheng" disabled placeholder="请填写实习岗位"></el-input>
        </el-form-item>
        <el-form-item label="实习开始日期" prop="internJoinDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.internJoinDate"
                          type="date"
                          placeholder="请选择实习开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实习终止日期" prop="internResignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.internResignDate"
                          type="date"
                          placeholder="请选择实习终止日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="签订日期" prop="internCertificationSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.internCertificationSignDate"
                          type="date"
                          placeholder="请选择离职日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('proveForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载实习证明
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传实习证明反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载实习证明反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {departStaffPracticeProve, departStaffPracticeProveReply, departStaffPracticeProveShow} from "@/api/resignation";
import {downLoadOfferWord} from "@/api/entry";
import {getPageName, zhDate} from "@/utils";
import {QAHOST, upFile} from "@/config";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      proveForm: {
        xingming: '',
        gangweimingcheng: '',
        internJoinDate: '',
        internResignDate: '',
        internCertificationSignDate: '',
      },
      rules: {
        xingming: rules.mustInput,
        gangweimingcheng: rules.mustInput,
        internJoinDate: rules.mustDate,
        internResignDate: rules.mustDate,
        internCertificationSignDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "实习证明", true);
    this.id = Number(this.$route.params.id)
    this.departStaffPracticeProveShow()
  },
  components: {},
  methods: {
    async departStaffPracticeProveShow() {
      const res = await departStaffPracticeProveShow(this.id)
      if (res.data) {
        this.proveForm = {
          xingming: res.data.xingming,
          gangweimingcheng: res.data.gangweimingcheng,
          internJoinDate: new Date(res.data.internJoinDate),
          internResignDate:new Date(res.data.internResignDate),
          internCertificationSignDate: new Date(res.data.internCertificationSignDate),
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        this.proveForm.xingming = info.data.practice_staff.xingming
        this.proveForm.gangweimingcheng = info.data.practice_staff.gangweimingcheng
        this.proveForm.internJoinDate = new Date(info.data.practice_staff.internJoinDate)
        this.proveForm.internResignDate = new Date(info.data.practice_staff.internResignDate)

      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffPracticeProve(
          this.id,
          this.proveForm.xingming,
          this.proveForm.gangweimingcheng,
          zhDate(new Date(this.proveForm.internJoinDate)),
          zhDate(new Date(this.proveForm.internResignDate)),
          zhDate(new Date(this.proveForm.internCertificationSignDate)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffPracticeProveShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.proveForm.xingming + '实习证明.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffPracticeProveReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffPracticeProveShow()
    },
  },
  filters: {},
  watch: {},
}
</script>


<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 280px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>

